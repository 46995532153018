<template>
  <div>
    <!-- Search input -->
    <h3 class="input-title">{{ searchTitle }}</h3>
    <label class="input-label">
      <!-- TODO: Modify alt texts either with Dutch texts or whatever suits best here.. -->
      <img class="search-icon" src="../../../assets/icons/icn_search.svg" alt="Search icon"/>
      <input :id="searchId"
             v-model="searchedTerm"
             class="search-input"
             :placeholder="placeholder"
             :class="placeholderClass"
             @input="handleInput"/>
      <!-- TODO: Modify alt texts either with Dutch texts or whatever suits best here.. -->
      <img v-if="searchedTerm"
           src="../../../assets/icons/icn_reject.svg"
           class="clear-search"
           alt="Clear search"
           @click="clear()"/>
    </label>

    <slot name="content-below" :searched-term="searchedTerm"/>
  </div>
</template>

<script>
import { ref, watch } from 'vue'

let searchTimeout

export default {
  name: 'Search',
  components: {},
  props: {
    searchTitle: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    searchId: {
      type: String,
      required: true
    },
    manageSearch: {
      type: Function,
      required: true
    },
    clearSearch: {
      type: Function,
      required: true
    },
    searchResults: {
      type: Array,
      required: true
    },
    value: {
      type: String,
      required: false,
      default: ''
    },

    // Not required
    searchDebounce: {
      type: Number,
      required: false,
      default: 500
    },
    placeholderClass: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    // Search input field
    const searchedTerm = ref('')
    if (props.value) {
      searchedTerm.value = JSON.parse(JSON.stringify(props.value))
    }


    watch(() => props.value, (curr, prev) => {
      searchedTerm.value = curr
    })

    function handleInput(data) {
      clearTimeout(searchTimeout)
      searchTimeout = setTimeout(updateParent, props.searchDebounce)
    }

    function updateParent() {
      props.manageSearch(searchedTerm.value)
    }

    function clear() {
      searchedTerm.value = ''
      props.clearSearch()
    }

    return {
      // Search input field
      searchedTerm,
      handleInput,
      clear,
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

$text-color: var(--blue_dark_01);

.input-label {
  position: relative;
}

.input-title {
  color: var(--blue_dark);
  font-size: rem(16);
  font-weight: 500;
  letter-spacing: 0;
  line-height: rem(19);
  margin-bottom: rem(8);
  text-align: left;
}

.search-input {
  border: none;
  border-radius: rem(8);
  background-color: white;
  box-shadow: rem(2) rem(2) 0 0 rgba(0, 0, 0, 0.1);
  color: $text-color;
  font-size: rem(16);
  height: rem(46);
  padding: rem(11) 0 rem(11) rem(56);
  width: 100%;
  &::placeholder {
    color: var(--blue_light_01);
  }

  &:focus {
    outline: none;
  }
}

.selected-placeholder{
  &::placeholder{
    color: black
  }
}

.clear-search {
  @include position(absolute, $top: 50%, $right: rem(23));
  height: rem(14);
  opacity: 0.3;
  transform: translateY(-50%);
  width: rem(14);
  cursor: pointer;
}

.search-icon {
  @include position(absolute, $top: 50%, $left: rem(16));
  @include hover-active-pointer();
  height: rem(18);
  transform: translateY(-50%);
  width: rem(18);
}
</style>
